import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import HeaderImage from "../../components/header/headerImage/headerImage";
import PersonCard from "../../components/card/personCard/personCard";
import TextImageContent from "../../components/textImageContent/textImageContent";

import "./style.scss";

import imgHeader from "../../images/nous-rejoindre/Header.webp";
import imgVGC from "../../images/nous-rejoindre/VGC.png";

import photo1 from "../../images/nous-rejoindre/Photo-1.webp";
import photo2 from "../../images/nous-rejoindre/Photo-2.webp";
import photo3 from "../../images/nous-rejoindre/Photo-3.webp";
import photo4 from "../../images/nous-rejoindre/Photo-4.webp";
import photo5 from "../../images/nous-rejoindre/Photo-5.webp";

import indexFemmesHommes from "../../../static/pdfs/Index_femmes-hommes_2024_pour_publication_2025.pdf"

import imgBlocImage from "../../images/nous-rejoindre/courtage.webp";
import iconDownloadColored from "../../images/icons/icon-download-colored.svg";

const RhPage = ({ location }) => {
  const rbb = {
    Prenom: "Vanessa",
    Nom: "GUICHERD-CALLIN",
    Poste: "Directrice des ressources humaines",
    Photo: imgVGC,
  };
  return (
    <>
      <Helmet>
        <body className="rh" />
      </Helmet>
      <Layout>
        <SEO title="Nous rejoindre" />
        <HeaderImage
          title="Nous rejoindre"
          text="Découvrez les valeurs que nous partageons avec nos collaborateurs. Assureur spécialiste du BTP, nous accompagnons nos collaborateurs dans le développement de leurs compétences et dans le partage de valeurs communes."
          imagePath={imgHeader}
          location={location}
          crumbLabel="Pourquoi nous rejoindre&nbsp;?"
        />

        <section>
          <h2>
            Le mot de <br/> notre <strong>DRH</strong>
          </h2>
          <div className="rh__drh">
            <p>
              «&nbsp;Notre volonté est de donner à chaque collaborateur entrant à
              L'Auxiliaire les{" "}
              <strong>clés de sa réussite professionnelle</strong> et de son
              évolution. Je ne peux qu'encourager les jeunes générations à
              s'inscrire dans nos pas et à venir <strong>enrichir</strong> notre
              culture d'entreprise&nbsp;!&nbsp;»
            </p>
            <PersonCard person={rbb} className="rh__person-card" />
          </div>
        </section>
        <section>
          <h2>
            Pourquoi <br /> <strong>nous rejoindre</strong>&nbsp;?
          </h2>

          <div className="rh__section-content">
            <TextImageContent
              imgPath={photo1}
              isImgFirst={false}
              imgAlt="réunion"
            >
              <p>
                L’Auxiliaire est une <strong>entreprise en croissance</strong>.
                Nos équipes ont grandi de <strong>+70%</strong> sur les 20
                dernières années afin de proposer à nos sociétaires une{" "}
                <strong>qualité de service</strong> toujours plus grande et de
                nous adapter à des environnements toujours plus évolutifs.
              </p>
            </TextImageContent>
            <TextImageContent
              imgPath={photo2}
              isImgFirst={true}
              imgAlt="réunion"
            >
              <p>
                Nous sommes l’assureur <strong>spécialiste</strong> des risques
                de la construction et de ses métiers dans le quart sud-est de la
                France, et ce depuis de très nombreuses années.
                <br />
                <br />
                Nous rejoindre, c’est bénéficier d’une{" "}
                <strong>formation en continue</strong>, de qualité afin de vous
                permettre d’exercer votre activité dans{" "}
                <strong>les meilleures conditions</strong>.
              </p>
            </TextImageContent>
            <TextImageContent
              imgPath={photo3}
              isImgFirst={false}
              imgAlt="batiment de L'Auxiliaire"
            >
              <p>
                Historiquement <strong>lyonnaise</strong>, notre entreprise est
                implantée au coeur du 6ème arrondissement de Lyon.
                <br />
                <br />
                Notre nouveau siège social, bâtiment de 4200m²{" "}
                <strong>rénové</strong>, <strong>collaboratif</strong> et{" "}
                <strong>vertueux</strong>, situé à deux pas du parc de la Tête
                d’Or, saura vous séduire.
              </p>
            </TextImageContent>
            <TextImageContent
              imgPath={photo4}
              isImgFirst={true}
              imgAlt="2 femmes discutent"
            >
              <p>
                La Direction des Ressources Humaines de L’Auxiliaire est{" "}
                <strong>à l’écoute</strong> des enjeux de l’entreprise et des
                mutations de son environnement socio-économique et répond par
                des politiques adaptées.
                <br />
                <br />
                <strong>
                  Accord sur l’égalité professionnelle entre les Femmes et les
                  Hommes, contrat de génération, temps partiels, télétravail
                </strong>
                ..., l’épanouissement personnel et professionnel de nos
                collaborateurs est au centre de notre politique RH.
              </p>
            </TextImageContent>
            <TextImageContent
              imgPath={photo5}
              isImgFirst={false}
              imgAlt="femme au téléphone"
            >
              <p>
                Nous sommes un <strong>employeur engagé</strong>. Nous
                favorisons l’accès à l’emploi pour les personnes en situation de
                handicap à travers notre partenariat avec{" "}
                <strong>Mission Handicap Assurance</strong>.
                <br />
                <br />
                Notre <strong>index égalité</strong> Femmes-Hommes en 2024 est de 90/100 pour notre UES L’Auxiliaire et L’Auxiliaire Vie (mise à jour 21 février 2025).
                <br />
                <br />
              </p>

                  <a href={indexFemmesHommes} className="btn-file--2" download>
                    <img src={iconDownloadColored} alt="icone download" />
                    <span>
                      Consultez l'infographie de notre Index égalité - Femmes/Hommes - 2024
                    </span>
                  </a>
            </TextImageContent>
          </div>
        </section>
        <BlocImage
          title="<strong>Nos équipes</strong> <br/> vous attendent"
          btnText="Voir nos offres"
          imagePath={imgBlocImage}
          btnUrl="/offres-emploi"
        />
      </Layout>
    </>
  );
};

export default RhPage;
